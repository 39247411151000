import React from "react";
import { navigate } from 'gatsby-link';
import { Container, Input } from 'reactstrap';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

export default class SignupForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            address: "",
            state: "",
            city: "",
            zipCode: "",
            testField: "",
            interested: "",
            comments: "",
            botField: ""
        };
    }

    handleSubmit = e => {
        const form = e.target
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "KCKContactFormv5", ...this.state })
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => alert(error));

        e.preventDefault();
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { firstName, lastName, email, phoneNumber, address, state, city, zipCode, interested, comments, botField } = this.state;
        return (
            <section>
                <Container>
                    <form name="KCKContactFormv5" id="contactForm" method="post" action="/thanks/" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit}>
                        <p>Fill out form below to receive information.</p>
                        <input type="hidden" name="form-name" value="KCKContactFormv5" />
                        <div className="form-group">
                            <label>First Name</label>
                            <input type="text" className="form-control" name="firstName" value={firstName} id="firstName" onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label>Last Name</label>
                            <input type="text" className="form-control" name="lastName" value={lastName} id="lastName" onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" className="form-control" name="email" value={email} id="email" onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label>Phone Number</label>
                            <input type="text" className="form-control" name="phoneNumber" value={phoneNumber} id="phoneNumber" onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label>Address</label>
                            <input type="text" className="form-control" name="address" value={address} id="address" onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label>State</label>
                            <input type="text" className="form-control" name="state" value={state} id="state" onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label>City</label>
                            <input type="text" className="form-control" name="city" value={city} id="city" onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label>Zip Code</label>
                            <input type="text" className="form-control" name="zipCode" value={zipCode} id="zipCode" onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label>Information Being Requested?</label>
                            <Input className="mb-3" type="select" name="interested" value={interested} onChange={this.handleChange}>
                                <option value="" name="">Choose Program</option>
                                <option value="Barber Program">Barber Program</option>
                                <option value="Restricted Barber Program">Restricted Barber Program</option>
                                <option value="Cosmetology Program">Cosmetology Program</option>
                                <option value="Full Specialist Program">Full Specialist Program</option>
                                <option value="Skin Care Program">Skin Care Program</option>
                                <option value="Manicure Program">Manicure Program</option>
                                <option value="Instructor Program">Instructor Program</option>
                            </Input>
                        </div>
                        <div className="form-group d-none">
                            <label>Don't fill this out if human:</label>
                            <input className="form-control" name="bot-field" value={botField} onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label>Questions/Comments?</label>
                            <textarea className="form-control" name="comments" id="comments" value={comments} onChange={this.handleChange}></textarea>
                        </div>
                        <input type="submit" value="Get Information" className="btn btn-danger" />
                    </form>
                </Container>
            </section>
        )
    }
}